import { UAParser } from 'ua-parser-js';
import { Framework } from './_framework.js';

let uaResult 	= UAParser();
let device 		= uaResult.device;
let browser 	= uaResult.browser.name.replace(/\s+/g, "").toLowerCase();
let $html 		= document.documentElement;
let isPage 		= "";
$html.classList.add(browser);
//console.log(uaResult);
$html.classList.add( uaResult.os.name.split(" ")[0].toLowerCase() );

var agent = window.navigator.userAgent.toLowerCase();
var ipad = agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1 && 'ontouchend' in document;


if(device.type === "mobile" || ipad){
	$html.classList.add("mobile");
	if(device.model === "iPhone"){
		$html.classList.add(device.model);
	}else{
		$html.classList.add("android");
	}
	if(ipad){
		$html.classList.add("ipad");
	}
}else{
	$html.classList.add("pc");
}

document.addEventListener('DOMContentLoaded', function () {
	let fw = new Framework();
});