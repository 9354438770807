import anime from 'anime.es.js';
export let rotateIcon = {
	current: [
		{ deg: -144, scale: 0, opacity: 0},
		{ deg: -72, scale: 0, opacity: 0},
		{ deg: 0, scale: 0, opacity: 0},
		{ deg: 0, scale: 0, opacity: 0},
		{ deg: 0, scale: 0, opacity: 0}
	],
	limit: [0, 72, 144, 216, 288, 360],
	targets: [],
	isStart: false,
	timer: "",
	init: function(){
		/**/
		let PI = Math.PI/180;
		rotateIcon.targets = document.querySelectorAll(".step_circle");
		let $steps = document.querySelectorAll(".list_steps .step");
		let blockW = document.querySelector(".chart_steps").clientWidth;
		let stepW = rotateIcon.targets[0].clientWidth;
		console.log(blockW, stepW)
		let r = (blockW - stepW)/2;
		rotateIcon.targets.forEach( ($step, index) => {
			let rad = rotateIcon.limit[index] - 90;
			let x = Math.cos( rad*PI )*r/stepW*100;
			let y = Math.sin( rad*PI )*r/stepW*100;
			//console.log(index, rotateIcon.current[index].deg, x, y);
			$step.style.transform 	= `translate3d(${x}%, ${y}%, 0)`;
		});
		//rotateIcon.start();
	},
	start: function(){
		let PI = Math.PI/180;
		let blockW = document.querySelector(".chart_steps").clientWidth;
		let stepW = rotateIcon.targets[0].clientWidth;
		let r = blockW*.5 - stepW/2;
		
		//console.log(r)
		if(!rotateIcon.isStart){
			rotateIcon.isStart = true;
			let steps = document.querySelectorAll(".list_steps .step");
			rotateIcon.targets.forEach( ($step, index) => {
				anime({
					targets: rotateIcon.current[index],
					deg: rotateIcon.limit[index] - 90,
					scale: 100,
					opacity: 100,
					easing: 'easeOutQuart',
					duration: 1200,
					delay: 200*index,
					update: function(){
						let rr = r*(rotateIcon.current[index].scale*0.01);
						let x = Math.cos( rotateIcon.current[index].deg*PI )*rr/stepW*100;
						let y = Math.sin( rotateIcon.current[index].deg*PI )*rr/stepW*100;
						//console.log(index, rotateIcon.current[index].deg, x, y);
						$step.style.transform 	= `translate3d(${x}%, ${y}%, 0)`;
						$step.style.opacity 	= `${rotateIcon.current[index].opacity/100}`;
						if( $step.style.opacity >= .3 ){
							steps[index].classList.add("animate");
						}
					}
				})
			});
			setTimeout(function(){
				document.querySelector(".icon_arrow").classList.add("active");
			}, 400);
		}
	},
	update: function(){
		
	}
}