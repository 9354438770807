export const loc = {
	all: function(){
		let locObj = {
			path: "",
			pathLen: "",
			params: {},
			pathArry: []
		}
		let path = location.pahname;
		locObj.path		= location.pathname;
		locObj.pathArry = locObj.path.split("/");
		locObj.pathLen	= locObj.pathArry.length;
		//パラメータ
		let params 		= location.search.substring(1);
		let paramArry	= params.split("&");
		for (let i = 0; paramArry[i]; i++) {
			let kv = paramArry[i].split("=");
			locObj.params[kv[0]] = kv[1];
		}
		return locObj;
	}
}