let m = {
	target: null,
	pos: [0, 0],
	next: [0, 0],
	draw: function(){
		let diffX = this.next[0] - this.pos[0];
		let diffY = this.next[1] - this.pos[1];
		diffX *= .999;
		diffY *= .999;
		m.target.style.transform = `translate3d(${m.pos[0] + diffX}px, ${m.pos[1] + diffY}px, 0)`;
	}
}
export let mouseStalker = {
	frameID: 0,
	init: function(){
		m.target = document.querySelector("#pointer");
		document.addEventListener("mousemove", mouseStalker.getMousePos, false);
		this.update();
		let allA = document.querySelectorAll("a");
		allA.forEach( ($a, index) => {
			$a.removeEventListener("mouseenter", mouseStalker.enter, false);
			$a.addEventListener("mouseenter", mouseStalker.enter, false);
			
			$a.removeEventListener("mouseleave", mouseStalker.leave, false);
			$a.addEventListener("mouseleave", mouseStalker.leave, false);
		});
		
		let allB = document.querySelectorAll("button");
		allB.forEach( ($b, index) => {
			$b.removeEventListener("mouseenter", mouseStalker.enter, false);
			$b.addEventListener("mouseenter", mouseStalker.enter, false);
			
			$b.removeEventListener("mouseleave", mouseStalker.leave, false);
			$b.addEventListener("mouseleave", mouseStalker.leave, false);
		});
	},
	enter: function(e){
		let $this = this;
		if( $this.getAttribute("data-hasimg") === ""){
			if( !m.target.classList.contains("hover_img") ){
				m.target.classList.add("hover_img");
			}
		}else{
			if( !m.target.classList.contains("hover") ){
				m.target.classList.add("hover");
			}
		}
	},
	leave: function(){
		m.target.className = "";
	},
	getMousePos: function(e){
		let mouse = [0, 0];
		if(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
		m.next[0] = mouse[0];
		m.next[1] = mouse[1];
		//console.log(m.next);
	},
	update: function(){
		m.draw();
		mouseStalker.frameID = requestAnimationFrame(mouseStalker.update);
	}
}