import { shareSNS } from './lib/_share.js';
import { Scroll } from './lib/_scroll.js';

export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
		common.addMail();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
		common.scrl = new Scroll();
		
		/*
		document.addEventListener("keyup", function(e){
			if(e.keyCode === 65){
				if( !document.documentElement.classList.contains("show_grid")){
					document.documentElement.classList.add("show_grid");
				}else if( document.documentElement.classList.contains("show_grid")){
					document.documentElement.classList.remove("show_grid");
				}
			}
		});
		*/
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	nav: ()=>{
		let $btnMenu = document.querySelector("[data-menu]");
		$btnMenu.removeEventListener("click", common.toggleNav, false);
		$btnMenu.addEventListener("click", common.toggleNav, false);
		
		let $bgNav = document.querySelector("#bg_nav");
		$bgNav.removeEventListener("click", common.toggleNav, false);
		$bgNav.addEventListener("click", common.toggleNav, false);
		
		let $scrollBtn = document.querySelector(".s-kv button.scroll");
		if($scrollBtn){
			$scrollBtn.removeEventListener("click", common.scrlToPos, false);
			$scrollBtn.addEventListener("click", common.scrlToPos, false);
		}
		//shareSNS.nav();
		
		/*
		let $scroll = document.querySelector(".btn_scroll");
		$scroll.addEventListener("click", function(){
			let id 		= this.getAttribute("data-id");
			let posY 	= document.querySelector("#" + id).getBoundingClientRect().top;
			common.scrl.scrollToPos(posY);
		})
		*/
	},
	scrlToPos: function(e){
		e.preventDefault();
		let id = this.getAttribute("data-id");
		let posY = document.querySelector("#" + id).getBoundingClientRect().top + window.pageYOffset;
		if(id === "section_about"){
			if ( window.matchMedia('(min-width: 744px)').matches ) {
				posY += 100;
			}else{
				//let safeArea = parseInt( getComputedStyle(document.documentElement).getPropertyValue('--safearea-bottom').replace("px", "") );
				//posY += safeArea;
				//console.log(safeArea);
			}
		}
		window.scroll({
			top: posY,
			behavior: "smooth"
		});
	},
	toggleNav: function(){
		let $html = document.documentElement;
		if( !$html.classList.contains("show_nav") ){
			$html.classList.add("show_nav");
			//common.getScrollBar();
		}else{
			$html.classList.remove("show_nav");
			//common.getScrollBar();
		}
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		let $kv = document.querySelector(".s-kv");
		
		addressbarH();
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
				if( $kv ){
					$kv.style.height = winH + "px";
				}
			}
		}
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		if(common.scrl){
			common.scrl.checkBlock();
			common.scrl.checkScroll();
		}
		setTimeout(function(){
			if(common.scrl){
				common.scrl.checkBlock();
				common.scrl.checkScroll();
			}
		}, 600);
		
		common.getScrollBar();
		common.beforeW = winW;
	},
	getScrollBar: function(){
		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
	},
	addMail: function(){
		let $btns = document.querySelectorAll("button[data-email]");
		let address = converter( String.fromCharCode(104,109,101,110,63,96,113,100,109,98,100,45,105,111) );
		$btns.forEach( ($button) => {
			$button.querySelector("span").innerHTML = address;
			$button.removeEventListener("click", sendMail, false);
			$button.addEventListener("click", sendMail, false);
		});
		function sendMail(){
			location.href = `mailto: ${address}`;
		}
		function converter(M){
			var str="", str_as="";
			for(var i=0;i<M.length;i++){
				str_as = M.charCodeAt(i);
				str += String.fromCharCode(str_as + 1);
			}
			return str;
		}
	}
}
