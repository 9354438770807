
export let swipeBlock = {
	target: "",
	touchArea: "",
	listLen: 0,
	current: 0,
	max: 0,
	canChange: true,
	isDragging: false,
	param: {
		start: 0,
		moveX: 0,
		currentPosX: 0
	},
	init: function(domName){
		let $article = document.querySelector("article");
		let $body = document.querySelector("body");
		if( $article ){
			$body.classList.remove("disable");
			setTimeout(function(){
				$article.classList.add("active");
				setTimeout(function(){
					$body.classList.add("active_nav");
				}, 600);
			}, 1600);
			swipeBlock.touchArea = document.querySelector(domName);
			swipeBlock.target 	= swipeBlock.touchArea.querySelector("ul");
			if(swipeBlock.target){
				swipeBlock.current 	= 0;
				swipeBlock.listLen 	= swipeBlock.target.querySelectorAll("li").length;
				if(swipeBlock.listLen > 3){
					swipeBlock.max = swipeBlock.listLen - 3;
				}else{
					swipeBlock.max = swipeBlock.listLen;
				}
				//console.log(swipeBlock.listLen);
				swipeBlock.nav();
			}
		}
	},
	nav: function(){
		if('ontouchstart' in window){
			
		}else{
			swipeBlock.touchArea.removeEventListener("mousedown", swipeBlock.touchStart, false);
			swipeBlock.touchArea.addEventListener("mousedown", swipeBlock.touchStart, false);
		}
	},
	touchStart: function(e){
		let sb = swipeBlock;
		if( sb.canChange ){
			sb.param.start = e.clientX;
			sb.touchArea.removeEventListener("mousemove", sb.touchMove, false);
			sb.touchArea.addEventListener("mousemove", sb.touchMove, false);
			
			document.removeEventListener("mouseup", sb.touchEnd, false);
			document.addEventListener("mouseup", sb.touchEnd, false);
			
		}
	},
	touchMove: function(e){
		e.preventDefault();
		let sb = swipeBlock;
		
		if(!sb.touchArea.classList.contains("touch")){
			sb.touchArea.classList.add("touch");
			sb.isDragging = true;
		}
		
		if(!sb.canChange){ return false; }
		let currentX 	= 0;
		let diffX 		= 0;
		if(sb.isDragging){
			currentX 	= e.clientX;
			diff 		= currentX - sb.param.start;
			sb.param.moveX = diff;
			let posX = sb.currentPosX + diff;
			// /document.querySelector("[data-slideshow]").clientWidth*100
			sb.target.style.transform = `translate3d(${posX}px, 0, 0)`;
		}
	},
	touchEnd: function(e){
		let sb = swipeBlock;
		let limit = 75;
		//console.log(kvNav.moveX);
		if(sb.touchArea.classList.contains("touch")){
			sb.touchArea.classList.remove("touch");
			sb.isDragging = false;
		}
		if(sb.param.moveX >= limit){
			sb.dec();
			sb.param.moveX = 0;
			sb.param.startX = 0;
		}else if(sb.param.moveX <= limit*-1){
			sb.inc();
			sb.param.moveX = 0;
			sb.param.startX = 0;
		}else{
			//kvNav.touchCancel();
			sb.canceled();
		}
		sb.isDragging = false;
		sb.touchArea.removeEventListener("mousemove", sb.touchMove, false);
	},
	canceled: function(){
		let sb = swipeBlock;
		console.log("canceled")
		if(sb.touchArea.classList.contains("touch")){
			sb.touchArea.classList.remove("touch");
			sb.isDragging = false;
		}
		sb.param.moveX 	= 0;
		sb.param.startX = 0;
		sb.target.style.transform = `translate3d(${sb.currentPosX}px, 0, 0)`;
		setTimeout(function(){
			if(sb.touchArea.classList.contains("moving")){
				sb.touchArea.classList.remove("moving");
			}
			sb.isDragging = false;
			sb.canChange = true;
		}, 500);
	},
	inc: function(){
		let sb = swipeBlock;
		sb.current++;
		console.log(sb.current, sb.max);
		if(sb.current > sb.max){
			sb.current = sb.max;
			sb.canceled();
		}else{
			sb.change();
		}
	},
	dec: function(){
		let sb = swipeBlock;
		sb.current--;
		if(sb.current < 0){
			sb.current = 0;
			sb.canceled();
		}else{
			sb.change();
		}
	},
	change: function(){
		let sb = swipeBlock;
		let marginLeft = Math.floor( window.innerWidth*0.020833 );
		sb.currentPosX = (sb.current * sb.target.querySelector("li a").clientWidth + sb.current * marginLeft)*-1;
		sb.target.style.transform = `translate3d(${sb.currentPosX}px, 0, 0)`;
	}
}