import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';
import { slideshow } from './lib/_slideshow.js';
import { swipeBlock } from './lib/_swipe.js';
import { movie } from './lib/_movie.js';
import { mouseStalker } from './lib/_mouseStalker.js';

export class Framework {
	constructor(){
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		console.log(this.pageinfo);
	}
	
	checkURL(){
		if(this.canClick){
			this.canClick = false;
			//path
			this.changeContent();
		}
	}
	
	nav(){
		
	}
	
	changeURL(){
		
	}
	
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		mouseStalker.init();
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		if( this.pageinfo.path.includes("project") ){
			swipeBlock.init(".c-list-related");
			movie.init();
		}else if(this.pageinfo.path === "/"){
			slideshow.init();
		}
		common.resize();
		common.nav();
		this.reset();
		this.nav();
	}
	
	reset(){
		this.canClick = true;
	}
}