export let slideshow = {
	timer: "",
	list: [],
	current: 0,
	max: 0,
	target: null,
	startTime: 0,
	duration: 3500,
	init: function(){
		slideshow.target 	= document.querySelector("[data-slideshow]");
		slideshow.list 		= slideshow.target.querySelectorAll("li");
		slideshow.current 	= 0;
		slideshow.max 		= slideshow.list.length;
		slideshow.change();
		//console.log(slideshow.current, slideshow.max);
		slideshow.startTime = slideshow.getNow();
		
	},
	check: function(){
		let current = slideshow.getNow();
		let diff = current - slideshow.startTime;
		if( diff >= slideshow.duration){
			slideshow.inc();
		}else{
			setTimeout(function(){
				slideshow.check();
			}, 100);
		}
	},
	inc: function(){
		slideshow.beforeChange();
		slideshow.current++;
		if(slideshow.current >= slideshow.max){
			slideshow.current = 0;
		}
		setTimeout(function(){
			slideshow.change();
		}, 300);
	},
	beforeChange: function(){
		slideshow.list.forEach( ($li, index) =>{
			if(slideshow.current === index){
				if( !$li.classList.contains("disable") ){
					$li.classList.add("disable");
				}
			}
		});
	},
	change: function(){
		slideshow.list.forEach( ($li, index) =>{
			if(slideshow.current === index){
				if( !$li.classList.contains("active") ){
					$li.classList.add("active");
				}
			}else{
				if($li.classList.contains("active")){
					$li.classList.remove("active");
				}
			}
		});
		slideshow.startTime = slideshow.getNow();
		setTimeout(function(){
			slideshow.list.forEach( ($li, index) =>{
				if( $li.classList.contains("disable") ){
					$li.classList.remove("disable");
				}
			});
			slideshow.check();
		}, 100);
	},
	getNow: function(){
		let now = new Date();
		return now.getTime();
	}
}