import { object } from './_obj.js';
import { rotateIcon } from './_rotateIcon.js';
let Block = {
	top: 0,
	show: 0
}

/*
座標
elem.getBoundingClientRect()
*/
let $this;
let $html = document.documentElement;
export class Scroll {
	constructor(){
		$this = this;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		
		this.checkBlock();
		this.checkScroll();
		//console.log(document.querySelector("body").getAttribute("data-page"))
		if(document.querySelector("body").getAttribute("data-page") === "service"){
			//rotateIcon.init();
		}
		document.addEventListener("scroll", ()=>{
			$this.topD = window.pageYOffset;
			$this.checkScroll();
		}, false);
	}
	
	checkScroll(){
		$this.btmD = $this.topD + window.innerHeight;
		for(let index in $this.animations){
			if( $this.btmD >= $this.animations[index].top[1] ){
				$this.activeAnimate(index);
			}
		}
		if( $this.topD >= window.innerHeight ){
			if( !$html.classList.contains("scrolled") ){
				$html.classList.add("scrolled");
			}
		}else{
			$html.classList.remove("scrolled");
		}
	}
	activeAnimate(i){
		let $a = $this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			if( $a.getAttribute("data-animate") === "chart-start" ){
				rotateIcon.init();
			}
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		const Animate = {
			top: []
		}
		$this.blockA = document.querySelectorAll("[data-animate]");
		$this.animations = [];
		$this.blockA.forEach( (item) => {
			let _a = object(Animate);
			_a.top = $this.getTop(item, window.innerHeight/3);
			$this.animations.push(_a);
		});
		//console.log($this.animations);
	}
	getTop($dom, offset){
		let t = Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		return [ t, Math.floor( t + offset )]
	}
	
	scrollToPos(posY){
		console.log("scroll to > ", posY);
		window.scrollTo({
			top: posY,
			behavior: "smooth"
		});
	}
}

export let ScrollTo = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollTo;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		//console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		let max = y - window.pageYOffset;
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			//let progress = 1 - current/duration;
			let progress = current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			let currentPos = max*easeProgress + $this.yOffset;
			//console.log(max*easeProgress, window.pageYOffset);
			window.scrollTo(0, currentPos );
		}, 33);
	}
}

