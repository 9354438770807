
window.onYouTubeIframeAPIReady = function() {
	let $movies = document.querySelectorAll(".movie_player");
	$movies.forEach( ($movie, index) => {
		movie.setYouTube($movie, index);
	});
	let $btns = document.querySelectorAll("[data-movie-play]");
	$btns.forEach( ($btn, index) => {
		$btn.removeEventListener("click", { idx: index, handleEvent: movie.playMovie }, false);
		$btn.addEventListener("click", { idx: index, handleEvent: movie.playMovie }, false);
	});
}
let $html = document.documentElement;
let movieArry = [];
let players = [];
export let movie = {
	ytPlayerReady: false,
	init: function(){
		var tag = document.createElement('script');
		tag.src = "//www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	},
	playMovie: function(e){
		let movieDom = document.querySelectorAll(".wrap_movie")[this.idx];
		movieDom.classList.add("play");
		players[this.idx].playVideo();
		console.log(this.idx);
	},
	setYouTube: function($dom, index){
		let shareURL = $dom.getAttribute("data-url");
		let urlArry = shareURL.split("/");
		let id = urlArry[urlArry.length - 1];
		//console.log(id);
		movieArry[index] = new YT.Player($dom, {
			videoId: id,
			playerVars: {
				autohide	: 1,
				controls	: 1,
				frameborder : 0,
				rel			: 0,
				start		: 0,
				showinfo 	: 0,
				wmode		: "transparent",
				autoplay	: 0,
				mute 		: 1,
				playsinline	: 1,
				enablejsapi	: 1,
				iv_load_policy: 3,
				origin: location.protocol + '//' + location.hostname + "/"
			},
			events: {
				'onReady': function(e){
					//console.log("is ready", $this.isAuto, id, movieID, $player);
					players[index] = e.target;
					//console.log(players);
				},
				'onStateChange': function(e){
					//console.log($this.player, $this.dom, e.data);
					if(e.data === 1){
					
					}else if(e.data === 0){
						
					}
				}
			}
		});
	}
}